import { MENU_BUILDER_DISTRIBUTION_MODES } from 'src/components/MenuManagement/MenuBuilder/const';
import { getAllMenuChannels } from 'src/services/catalog';
import { sortMenuCategories, sortPOSMenusSequence, updateCategoryDisplayColor } from 'src/services/meal';
import { fetchRestaurantMenus } from 'src/services/menuBuilder';
import { USER_KEYS, getSessionItem } from 'src/store/storage';

const BASE = 'MENU_BUILDER';

export const actions = {
  loadingMenuTree: `${BASE}_LOADING_MENU_TREE`,
  loadMenuTreeSuccess: `${BASE}_LOAD_EMNU_TREE_SUCCESS`,
  loadMenuTreeFailed: `${BASE}_LOAD_EMNU_TREE_FAILED`,

  loadingMenuChannels: `${BASE}_LOADING_MENU_CHANNELS`,
  loadMenuChannelsDone: `${BASE}_LOAD_MENU_CHANNELS_DONE`,

  sortingMenus: `${BASE}_SORTING_MENUS`,
  sortMenusSuccess: `${BASE}_SORT_MENUS_SUCCESS`,
  sortMenusFailed: `${BASE}_SORT_MENUS_FAILED`,
  sortingCategories: `${BASE}_SORTING_CATEGORIES`,
  sortCategoriesSuccess: `${BASE}_SORT_CATEGORIES_SUCCESS`,
  sortCategoriesFailed: `${BASE}_SORT_CATEGORIES_FAILED`,
  menuCacheChanged: `${BASE}_MENU_CACHE_CHANGED`,
  categoryCacheChanged: `${BASE}_CATEGORY_CACHE_CHANGED`,
  updateMenuPathBeforeSearch: `${BASE}_UPDATE_MENU_PATH_BEFORE_SEARCH`,
};

export const loadRestaurantMenus = async ({ restaurant_id, page = 1, page_size, totalPageData }) => {
  let response;

  try {
    response = await fetchRestaurantMenus({ restaurant_id, page, page_size });
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return response;

  const {
    menus,
    meta: { pages },
  } = response.data;
  const { current_page, total_pages } = pages;
  if (!totalPageData) totalPageData = Array(total_pages).fill(null);
  totalPageData[page - 1] = menus;

  if (current_page === 1 && total_pages > current_page) {
    const promises = [];

    for (let i = 2; i <= total_pages; i++) {
      const promise = loadRestaurantMenus({ restaurant_id, page: i, page_size, totalPageData });
      promises.push(promise);
    }

    await Promise.all(promises);
  }

  return { success: true, data: totalPageData };
};

export const fetchMenuTree = () => async (dispatch) => {
  const restaurantId = getSessionItem(USER_KEYS.restaurantId);

  dispatch({ type: actions.loadingMenuTree });

  const response = await loadRestaurantMenus({
    restaurant_id: restaurantId,
    page: 1,
    page_size: 100,
  });

  if (!response.success) {
    dispatch({
      type: actions.loadMenuTreeFailed,
    });
    return;
  }

  const responseData = (response.data || []).reduce((prev, current) => {
    if (current && current.length) prev.push(...current);
    return prev;
  }, []);
  const menusGroupedByContract = {};
  const menuCacheMap = {};
  const categoryCacheMap = {};
  responseData.forEach((menu) => {
    const { restaurant_contract, categories, ...other } = menu;
    if (!categories.length) return;
    const { distribution_mode } = restaurant_contract;
    let contractCache = menusGroupedByContract[distribution_mode];
    if (!contractCache) {
      contractCache = {
        ...restaurant_contract,
        menu_ids: [],
        sequence: MENU_BUILDER_DISTRIBUTION_MODES.indexOf(distribution_mode),
      };
      menusGroupedByContract[distribution_mode] = contractCache;
    }
    const menuId = String(menu.id);
    contractCache.menu_ids.push(menuId);
    const categoryIds = [];
    categories.forEach((category) => {
      const categoryId = String(category.id);
      if (!categoryCacheMap[categoryId]) {
        categoryCacheMap[categoryId] = {
          ...category,
          menu_id: menuId,
          distribution_mode,
          displayColorCache: {},
        };
      }
      categoryCacheMap[categoryId].displayColorCache[menuId] = category.display_color;
      categoryIds.push(categoryId);
    });
    menuCacheMap[menuId] = {
      ...other,
      restaurant_contract,
      id: menuId,
      category_ids: categoryIds,
      distribution_mode,
    };
  });
  Object.values(menusGroupedByContract).forEach((contract) => {
    contract.menu_ids.sort((a, b) => menuCacheMap[a].sequence - menuCacheMap[b].sequence);
  });
  dispatch({
    type: actions.loadMenuTreeSuccess,
    payload: {
      menusGroupedByContract,
      menuCacheMap,
      categoryCacheMap,
    },
  });
};

export const sortMenusWithinContract =
  ({ distributionMode, menuIds, callback }) =>
  async (dispatch, getState) => {
    dispatch({ type: actions.sortingMenus });

    const restaurantId = getSessionItem(USER_KEYS.restaurantId);
    let response;

    try {
      response = await sortPOSMenusSequence(restaurantId, menuIds);
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) {
      dispatch({ type: actions.sortMenusFailed });
      return;
    }

    const { menusGroupedByContract } = getState().menuBuilder;
    if (!menusGroupedByContract) return;

    const clonedContractMap = { ...menusGroupedByContract };

    const menusOfContract = clonedContractMap[distributionMode];
    if (menusOfContract) {
      menusOfContract.menu_ids = menuIds;
    }

    dispatch({
      type: actions.sortMenusSuccess,
      payload: clonedContractMap,
    });

    typeof callback === 'function' && callback();
  };

export const sortCategoryWithinMenu =
  ({ menuId, categoryIds, callback }) =>
  async (dispatch, getState) => {
    dispatch({ type: actions.sortingCategories });

    const restaurantId = getSessionItem(USER_KEYS.restaurantId);
    let response;

    try {
      response = await sortMenuCategories({ restaurantId, menuId, categoryIds });
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) {
      dispatch({ type: actions.sortCategoriesFailed });
      return;
    }

    const { menuCacheMap } = getState().menuBuilder;
    const clonedMenuCacheMap = { ...menuCacheMap };
    const menu = clonedMenuCacheMap[menuId];
    if (menu) {
      clonedMenuCacheMap[menuId] = {
        ...menu,
        category_ids: categoryIds,
      };
    }
    dispatch({
      type: actions.sortCategoriesSuccess,
      payload: clonedMenuCacheMap,
    });

    typeof callback === 'function' && callback();
  };

export const removeCategoryWithinMenu =
  ({ menuId, categoryId }) =>
  async (dispatch, getState) => {
    const { menuCacheMap } = getState().menuBuilder;
    const newMenuCacheMap = { ...menuCacheMap };
    const menu = newMenuCacheMap[menuId];
    if (!menu) return;
    const categoryIds = [...menu.category_ids];
    const index = categoryIds.indexOf(categoryId);
    if (index < 0) return;
    categoryIds.splice(index, 1);
    menu.category_ids = categoryIds;
    dispatch({
      type: actions.menuCacheChanged,
      payload: newMenuCacheMap,
    });
  };

export const changeCategoryColorWithinMenu =
  ({ menu_id, category_id, display_color }, callback) =>
  async (dispatch, getState) => {
    const restaurantId = getSessionItem(USER_KEYS.restaurantId);
    let response;

    try {
      response = await updateCategoryDisplayColor({
        restaurant_id: restaurantId,
        menu_id,
        category_id,
        display_color,
      });
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) return;

    const { categoryCacheMap } = getState().menuBuilder;
    const _category = categoryCacheMap[category_id];

    if (!_category) {
      if (typeof callback === 'function') callback();
      return;
    }

    const newCategoryCacheMap = { ...categoryCacheMap };
    const category = newCategoryCacheMap[category_id];
    category.displayColorCache[menu_id] = display_color;
    dispatch({
      type: actions.categoryCacheChanged,
      payload: newCategoryCacheMap,
    });
    if (typeof callback === 'function') callback();
  };

export const changeMenuBasicInfo =
  ({ menu_id, name, foreign_name }) =>
  async (dispatch, getState) => {
    const { menuCacheMap } = getState().menuBuilder;
    const newMenuCacheMap = { ...menuCacheMap };
    const menu = newMenuCacheMap[menu_id];
    if (!menu) return;

    const newMenu = { ...menu };
    newMenu.name = name;
    newMenu.foreign_name = foreign_name;
    newMenuCacheMap[menu_id] = newMenu;
    dispatch({
      type: actions.menuCacheChanged,
      payload: newMenuCacheMap,
    });
  };

export const cacheMenuPathBeforeSearch = (menuPath) => async (dispatch, getState) => {
  const { menuPathBeforeSearch } = getState().menuBuilder;
  if (menuPathBeforeSearch) return;

  dispatch({
    type: actions.updateMenuPathBeforeSearch,
    payload: menuPath,
  });
};

export const clearMenuPathBeforeSearch = () => async (dispatch) => {
  dispatch({
    type: actions.updateMenuPathBeforeSearch,
    payload: undefined,
  });
};

export const fetchAllMenuChannels = () => async (dispatch, getState) => {
  const { isLoadedMenuChannels } = getState().menuBuilder;
  if (isLoadedMenuChannels) return;

  dispatch({ type: actions.loadingMenuChannels });
  let response;

  try {
    response = await getAllMenuChannels();
  } catch (e) {
    response = { success: false };
  }

  dispatch({
    type: actions.loadMenuChannelsDone,
    payload: {
      success: response.success,
      data: response.success ? response.data || [] : [],
    },
  });
};
