import { GET, POST } from 'src/utils/request';
import { QRCODE_API_PREFIX } from 'src/consts';

export function getMenuDecorations() {
  return GET(`${QRCODE_API_PREFIX}/v1/decorated_menus`);
}

export function createOrUploadMenuDecorations(payload) {
  return POST(`${QRCODE_API_PREFIX}/v1/decorated_menus`, payload);
}

export function recordChangeOfMenuDecorationMode({ previous_mode, current_mode }) {
  return POST(`${QRCODE_API_PREFIX}/v1/decorated_menus/switch_log`, {
    previous_mode,
    current_mode,
  });
}
