import { GET, PATCH } from 'src/utils/request';
import { CATALOG_SERVICE_API_PREFIX } from 'src/consts/third_party';

export async function getAllMenuChannels() {
  return GET(`${CATALOG_SERVICE_API_PREFIX}/api/v1/channels`);
}

export async function bindMenuChannels({ restaurant_id, menu_id, channel_names }) {
  return PATCH(`${CATALOG_SERVICE_API_PREFIX}/api/v1/menus/${menu_id}/bind_channel`, {
    restaurant_id,
    menu_id,
    channel_names,
  });
}
