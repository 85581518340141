import { message } from 'antd';
import { USER_KEYS, getSessionItem } from 'src/store/storage';
import { loadRestaurantMenus } from 'src/actions/menuBuilder';
import { DISTRIBUTION_MODES } from 'src/consts';
import { fetchAllMealsByFilter, validateMeal } from 'src/components/MenuManagement/MenuBuilder/utils';
import { createOrUploadMenuDecorations, getMenuDecorations } from 'src/services/menuDecoration';
import {
  ALIGEMENT_LINE_DIRECTION,
  ERROR_TYPES_TO_DETECT,
  HOTSPOT_LINE_HEIGHT,
  MENU_DECORATION_ERRORS,
  MENU_PAGE_ORITATION_ENUMS,
  TARGET_DISPLAY_SIZE,
} from 'src/consts/menuDecorate';
import {
  detectHotspotIntersection,
  getHotspotsOfDisplayingPage,
  getParamsFromLocation,
  getShowCategoryForCurrentMenuDecorationMode,
} from 'src/utils/menu';
import { modalMethods } from 'src/components/POSModal';

const { WIDTH, HEIGHT } = TARGET_DISPLAY_SIZE;
const { NO_BACKGROUND_IMAGE, HOTSPOTS_OVERLAPS, HOTSPOT_WITHOUT_MEAL } = MENU_DECORATION_ERRORS;

export const MENU_DECORATE_ACTIONS = {
  // actions for loading menus
  LOADING_QR_MENUS: 'loading_qr_menus',
  LOAD_QR_MENUS_FAILED: 'load_qr_menus_failed',
  LOAD_QR_MENUS_SUCCESS: 'load_qr_menus_success',
  // actions for loading meals
  LOADING_MEALS: 'loading_meals',
  LOAD_MEALS_SUCCESS: 'load_meals_success',
  LOAD_MEALS_FAILED: 'load_meals_failed',
  // actions for loading decorations
  LOADING_MENU_DECORATIONS: 'loading_menu_decorations',
  LOAD_MENU_DECORATIONS_FAILED: 'load_menu_decorations_failed',
  LOAD_MENU_DECORATIONS_SUCCESS: 'load_menu_decorations_success',
  // actions for page
  ADD_PAGE_TO_MENU: 'add_page_to_menu',
  ADD_PAGE_TO_CATEGORY: 'add_page_to_category',
  UPDATE_PAGE_NAME: 'update_page_name',
  REMOVE_PAGE_FROM_MENU: 'remove_page_from_menu',
  REMOVE_PAGE_FROM_CATEGORY: 'remove_page_from_category',
  SET_EDITING_PAGE_ID: 'set_current_editing_page_id',
  ADD_COMPONENT_TO_PAGE: 'add_component_to_page',
  ADJUST_COMPONENT_CONFIGS: 'adjust_component_configs',
  ADJUST_COMPONENT_POSITION: 'adjust_component_position',
  ADJUST_CONPONENT_DISH_LINK: 'adjust_component_dish_link',
  DELETE_CURRENT_COMPONENT: 'delete_component_from_page',
  DUPLICATE_COMPONENT_TO_PAGE: 'duplicate_component_to_page',
  SORT_PAGES_IN_MENU: 'sort_pages_in_menu',
  SORT_PAGES_IN_CATEGORY: 'sort_pages_in_category',
  UPDATE_BACKGROUND_IMAGE_FOR_PAGE: 'update_background_image_for_page',
  UPDATE_THUMBNAIL_IMAGE_FOR_PAGE: 'update_thumbnail_image_for_page',
  SET_CURRENT_EDITING_COMPONENT: 'set_current_editing_component',
  CANCEL_PAGE_MODIFICATIONS: 'cancel_page_modifications',
  // global actions
  SET_INITIAL_STATUS: 'set_orientation',
  CHANGE_THEME: 'change_theme',
  CHANGE_LOCATION: 'change_location',
  SCALE_CHANGED: 'scale_changed',
  CLEAR_CACHE_INFOS: 'clear_cache_infos',
  // save actions
  CLEAR_DIRTY_STATE: 'clear_dirty_state',
  SAVE_MENU_DECORATION_SUCCESS: 'save_menu_decoration_success',
  UPDATE_MENU_DECOARATION_SUCCESS: 'update_menu_decoration_success',
};

function getPageSummaryData(categoryPageIdsMap) {
  const categoryIdsOfPages = {};
  const pageIdsOfMenus = {};
  const sequencesOfPage = {};
  const categoryPathes = Object.keys(categoryPageIdsMap);
  categoryPathes.sort((a, b) => categoryPageIdsMap[a].sequence - categoryPageIdsMap[b].sequence);
  const currentSequenceOfMenus = {};

  categoryPathes.forEach((path) => {
    const [menuId, categoryId] = path.split('-');
    const { page_ids } = categoryPageIdsMap[path];
    page_ids.forEach((pageId) => {
      let sequence = currentSequenceOfMenus[menuId];
      if (sequence === undefined) sequence = currentSequenceOfMenus[menuId] = 0;
      categoryIdsOfPages[pageId] = categoryIdsOfPages[pageId] || [];
      categoryIdsOfPages[pageId].push(categoryId);
      sequencesOfPage[pageId];
      pageIdsOfMenus[menuId] = pageIdsOfMenus[menuId] || [];
      pageIdsOfMenus[menuId].push(pageId);
    });
  });

  return {
    categoryIdsOfPages,
    pageIdsOfMenus,
  };
}

async function loadMealsOfMenus(menuIds) {
  const payload = {
    menu_ids: menuIds,
  };
  const response = await fetchAllMealsByFilter(payload);
  if (!response.success) return response;

  const meals = response.data.reduce((prev, current) => {
    if (current && current.length) {
      current.forEach((meal) => {
        if (validateMeal(meal)) {
          prev[meal.id] = {
            ...meal,
            menu_id: meal.menu.id,
            category_id: meal.category.id,
          };
        }
      });
    }
    return prev;
  }, {});
  return { success: true, data: meals };
}

export const loadMenuDecorations = () => async (dispatch, getState) => {
  dispatch({ type: MENU_DECORATE_ACTIONS.LOADING_MENU_DECORATIONS });

  let response;

  try {
    response = await getMenuDecorations();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({ type: MENU_DECORATE_ACTIONS.LOAD_MENU_DECORATIONS_FAILED });
    return;
  }

  const menuDecorationMap = (response.data.data || []).reduce((prev, current) => {
    prev[current.orientation] = current;
    return prev;
  }, {});
  const { menuDecorate, preference } = getState();
  const showCategory = getShowCategoryForCurrentMenuDecorationMode({
    orientation: menuDecorate.orientation,
    preference: preference.preference,
  });

  dispatch({
    type: MENU_DECORATE_ACTIONS.LOAD_MENU_DECORATIONS_SUCCESS,
    payload: { menuDecorationMap, showCategory },
  });
};

export const saveMenuDecoration =
  ({ showCategory, successTips, successCallback, errorsDetectedCallback }) =>
  async (dispatch, getState) => {
    const { menuDecorate } = getState();
    const { menuBriefInfoMap, pageInfoMap, componentsMap, categoryPageIdsMap } = menuDecorate;
    const errorsByType = {};
    const pageSummaryData = getPageSummaryData(categoryPageIdsMap);

    const menus = Object.keys(menuBriefInfoMap).map((menuId) => {
      const menuInfo = menuBriefInfoMap[menuId];
      const { page_ids } = menuInfo;
      const pages = (page_ids || []).reduce((prev, pageId, index) => {
        const pageInfo = pageInfoMap[pageId];
        if (!pageInfo) return prev;

        const { name, background_image_url, component_ids, thumbnail_image_url } = pageInfo;
        let categoryIds = [];
        let sequence = index;
        if (showCategory) {
          categoryIds = pageSummaryData.categoryIdsOfPages[pageId] || [];
          const pageIds = pageSummaryData.pageIdsOfMenus[menuId] || [];
          sequence = pageIds.indexOf(pageId) + 1;
        }

        if (!background_image_url) {
          errorsByType[NO_BACKGROUND_IMAGE] = true;
        }

        const hot_spots = (component_ids || []).reduce((acc, componentId) => {
          const hotspot = componentsMap[componentId];
          if (!hotspot) return acc;

          const clonedHotspot = JSON.parse(JSON.stringify(hotspot));
          const { item_config, position, dish_info } = clonedHotspot;
          const { dish_config } = item_config;
          const { dish_name, dish_detail, action_button } = dish_config;

          if (dish_info?.id) {
            dish_config.meal_instance_id = dish_info.id;
          } else {
            errorsByType[HOTSPOT_WITHOUT_MEAL] = true;
          }

          const dishNameFont = dish_name.font;
          const dishNameFontSizeMapping = dishNameFont.size_mapping;
          dishNameFontSizeMapping.en = String(dishNameFontSizeMapping.en);
          dishNameFontSizeMapping.zh = String(dishNameFontSizeMapping.zh);
          delete dishNameFont.size;
          const dishDetailFont = dish_detail.font;
          const dishDetailFontSizeMapping = dishDetailFont.size_mapping;
          dishDetailFontSizeMapping.en = String(dishDetailFontSizeMapping.en);
          dishDetailFontSizeMapping.zh = String(dishDetailFontSizeMapping.zh);
          delete dishDetailFont.size;
          action_button.size = String(action_button.size);
          const hotspotPayload = {
            position,
            ...item_config,
          };
          acc.push(hotspotPayload);
          return acc;
        }, []);
        const hasIntersection = detectHotspotIntersection(hot_spots);
        if (hasIntersection && !errorsByType[HOTSPOTS_OVERLAPS]) {
          errorsByType[HOTSPOTS_OVERLAPS] = true;
        }

        prev.push({
          sequence,
          name,
          background_image_url,
          thumbnail_image_url,
          hot_spots,
          category_ids: categoryIds,
        });

        return prev;
      }, []);

      return {
        menu_id: menuId,
        pages,
      };
    }, []);

    if (Object.values(errorsByType).length) {
      let error;

      for (let i = 0; i < ERROR_TYPES_TO_DETECT.length; i++) {
        const errorType = ERROR_TYPES_TO_DETECT[i];
        const hasError = errorsByType[errorType];
        if (hasError) error = { type: errorType };
      }

      typeof errorsDetectedCallback === 'function' && errorsDetectedCallback(error);
      return;
    }

    const { orientation, menuDecorationMap, currentThemeConfig } = menuDecorate;
    const isLandscape = orientation === MENU_PAGE_ORITATION_ENUMS.LANDSCAPE;
    const meta = {
      root_font_size: '14',
      canvas_width: String(isLandscape ? WIDTH : HEIGHT),
      canvas_height: String(isLandscape ? HEIGHT : WIDTH),
      line_height: HOTSPOT_LINE_HEIGHT,
    };
    const config = {
      theme_name: currentThemeConfig.theme_name,
      background_color: currentThemeConfig.background_color,
      custom_color: currentThemeConfig.background_color,
      content_color: currentThemeConfig.content_color,
      mode: currentThemeConfig.mode,
      meta,
      menus,
    };
    const oldMenuDecorationPlan = menuDecorationMap[orientation];
    const menuDecorationPayload = {
      id: oldMenuDecorationPlan?.id,
      orientation,
      config,
    };

    modalMethods.showLoading({ messageId: 'saving-data' });

    let response;

    try {
      response = await createOrUploadMenuDecorations([menuDecorationPayload]);
    } catch (e) {
      response = { success: false };
    }

    modalMethods.hideLoading();
    if (!response.success) return;

    message.success(successTips);

    const newMenuDecorationMap = (response.data.data || []).reduce((prev, current) => {
      prev[current.orientation] = current;
      return prev;
    }, {});
    dispatch({
      type: MENU_DECORATE_ACTIONS.SAVE_MENU_DECORATION_SUCCESS,
      payload: newMenuDecorationMap,
    });

    if (typeof successCallback === 'function') {
      // setTimeout to insure execute sequence
      setTimeout(successCallback, 10);
    }
  };

export const loadMealsOfQRMenus = (menuIds) => async (dispatch, getState) => {
  let _menuIds = menuIds;
  if (!_menuIds) {
    const { menus } = getState().menuDecorate;
    _menuIds = menus.map((_) => _.id);
  }

  if (!_menuIds || !_menuIds.length) return;

  dispatch({ type: MENU_DECORATE_ACTIONS.LOADING_MEALS });
  const response = await loadMealsOfMenus(_menuIds);

  if (!response.success) {
    dispatch({ type: MENU_DECORATE_ACTIONS.LOAD_MEALS_FAILED });
    return;
  }

  dispatch(loadMenuDecorations());
  dispatch({
    type: MENU_DECORATE_ACTIONS.LOAD_MEALS_SUCCESS,
    payload: { meals: response.data },
  });
};

export const loadQRMenus = () => async (dispatch) => {
  dispatch({ type: MENU_DECORATE_ACTIONS.LOADING_QR_MENUS });

  const restaurantId = getSessionItem(USER_KEYS.restaurantId);
  const response = await loadRestaurantMenus({
    restaurant_id: restaurantId,
    page: 1,
    page_size: 100,
  });

  if (!response.success) {
    dispatch({ type: MENU_DECORATE_ACTIONS.LOAD_QR_MENUS_FAILED });
    return;
  }

  const menus = (response.data || []).reduce((acc, menuGroup) => {
    menuGroup.forEach((menu) => {
      if (
        menu.restaurant_contract?.distribution_mode === DISTRIBUTION_MODES.SMART_ORDERING &&
        menu.categories?.length > 0
      ) {
        acc.push(menu);
      }
    });
    return acc;
  }, []);
  menus.sort((a, b) => a.sequence - b.sequence);
  const menuIds = menus.map((_) => _.id);
  dispatch(loadMealsOfQRMenus(menuIds));
  dispatch({
    type: MENU_DECORATE_ACTIONS.LOAD_QR_MENUS_SUCCESS,
    payload: { menus },
  });
};

export const detectCurrentPageHasChanges = (callback) => async (_, getState) => {
  const { location, pageDirtyMap, pageInfoMap } = getState().menuDecorate;
  const { pageId } = getParamsFromLocation(location);
  let detectResult;

  if (!pageId) {
    detectResult = { detect: false };
  } else {
    const pageInfo = pageInfoMap[pageId];
    const hasBackgroundImage = !!pageInfo?.background_image_url;
    const dirty = pageDirtyMap[pageId];
    detectResult = { detect: true, hasBackgroundImage, dirty };
  }
  typeof callback === 'function' && callback(detectResult);
};

export const detectBackgroundImageChanged = (imageUrl, callback) => (_, getState) => {
  const { location, pageInfoMap } = getState().menuDecorate;
  const { pageId } = getParamsFromLocation(location);
  const pageInfo = pageInfoMap[pageId];
  let shouldConfirm = false;
  if (pageInfo?.background_image_url) {
    shouldConfirm = pageInfo.background_image_url !== imageUrl && (pageInfo.component_ids || []).length > 0;
  }
  typeof callback === 'function' && callback(shouldConfirm);
};

function checkMenuHasDecoratedMeals(menu, meals) {
  let hasMeals = false;
  const { pages } = menu;
  if (!pages || !pages.length) return hasMeals;

  for (let i = 0; i < pages.length; i++) {
    const page = pages[i];
    const { hot_spots } = page;
    if (!hot_spots || !hot_spots.length) continue;

    for (let j = 0; j < hot_spots.length; j++) {
      const { dish_config } = hot_spots[j];
      const { meal_instance_id } = dish_config;
      if (meal_instance_id && !!meals[meal_instance_id]) {
        hasMeals = true;
        break;
      }
    }
  }

  return hasMeals;
}

export const findUncoreatedMenus =
  ({ layout, callback }) =>
  (_, getState) => {
    const { menus, menuDecorationMap, meals } = getState().menuDecorate;
    const menuDecoration = menuDecorationMap[layout];

    if (!menuDecoration) {
      callback(menus);
      return;
    }

    let undecoreatedMenus = [];
    const { menus: decorationMenus } = menuDecoration.config;
    menus.forEach((menu) => {
      const decorationMenu = decorationMenus.find((_) => _.menu_id === String(menu.id));
      if (!decorationMenu) {
        undecoreatedMenus.push(menu);
        return;
      }

      const hasMeals = checkMenuHasDecoratedMeals(decorationMenu, meals);
      if (!hasMeals) undecoreatedMenus.push(menu);
    });

    typeof callback === 'function' && callback(undecoreatedMenus);
  };

export const cancelPageModifications = (actionData) => (dispatch) => {
  dispatch({
    type: MENU_DECORATE_ACTIONS.CANCEL_PAGE_MODIFICATIONS,
    payload: actionData instanceof Function ? null : actionData,
  });
};

export const detectAlignmentLines =
  ({ position, isHandling, callback }) =>
  (dispatch, getState) => {
    const { location, editingComponentId, pageInfoMap, componentsMap } = getState().menuDecorate;
    const { pageId } = getParamsFromLocation(location);
    const hotspots = getHotspotsOfDisplayingPage({ pageId, pageInfoMap, componentsMap });
    dispatch({
      type: MENU_DECORATE_ACTIONS.ADJUST_COMPONENT_POSITION,
      payload: { position },
    });

    const lines = [];
    if (!isHandling || hotspots.length < 2) {
      typeof callback === 'function' && callback(lines);
      return;
    }

    const { x: tl, y: tt, width: tw, height: th } = position;
    const tr = tl + tw;
    const tb = tt + th;
    const tc = tl + tw / 2;
    const tm = tt + th / 2;

    for (const hotspot of hotspots) {
      if (hotspot.id === editingComponentId) continue;

      const { x: left, y: top, width, height } = hotspot.position;
      const right = left + width;
      const bottom = top + height;
      const center = left + width / 2;
      const middle = top + height / 2;

      const _top = Math.min(tt, top);
      const _bottom = Math.max(tb, bottom);
      const _left = Math.min(tl, left);
      const _right = Math.max(tr, right);

      // aligned by left
      if (tl === left) {
        const _height = _bottom - _top;
        lines.push({
          left: tl,
          top: _top,
          height: _height,
          direction: ALIGEMENT_LINE_DIRECTION.VERTICAL,
        });
      }

      // aligned by right
      if (tr === right) {
        const _height = _bottom - _top;
        lines.push({
          left: tr,
          top: _top,
          height: _height,
          direction: ALIGEMENT_LINE_DIRECTION.VERTICAL,
        });
      }

      // aligned by center
      if (tc === center) {
        const _height = _bottom - _top;
        lines.push({
          left: tc,
          top: _top,
          height: _height,
          direction: ALIGEMENT_LINE_DIRECTION.VERTICAL,
        });
      }

      // aligned by top
      if (tt === top) {
        const _width = _right - _left;
        lines.push({
          top: tt,
          left: _left,
          width: _width,
          direction: ALIGEMENT_LINE_DIRECTION.HORIZONTAL,
        });
      }

      // aligned by bottom
      if (tb === bottom) {
        const _width = _right - _left;
        lines.push({
          top: tb,
          left: _left,
          width: _width,
          direction: ALIGEMENT_LINE_DIRECTION.HORIZONTAL,
        });
      }

      // aligned by middle
      if (tm === middle) {
        const _width = _right - _left;
        lines.push({
          top: tm,
          left: _left,
          width: _width,
          direction: ALIGEMENT_LINE_DIRECTION.HORIZONTAL,
        });
      }
    }

    typeof callback === 'function' && callback(lines);
  };

export const handleShowCategoryToggleChanged =
  ({ layout, showCategory, callback }) =>
  async (dispatch, getState) => {
    const menuDecorationState = getState().menuDecorate;
    const { menuDecorationMap, meals, menus: menusForMeals } = menuDecorationState;
    const menuDecoration = menuDecorationMap[layout];
    if (!menuDecoration) {
      callback();
      return;
    }

    const clonedMenuDecoration = JSON.parse(JSON.stringify(menuDecoration));
    const { menus } = clonedMenuDecoration.config;
    if (!menus.length) {
      callback();
      return;
    }

    for (const menu of menus) {
      const { pages, menu_id } = menu;
      if (!pages || !pages.length) continue;

      const unCategoriedPages = [];
      const existedCategoryIds = [];

      for (const page of pages) {
        const { hot_spots } = page;
        page.category_ids = [];
        if (!showCategory) continue;

        if (!hot_spots || !hot_spots.length) {
          unCategoriedPages.push(page);
          continue;
        }

        let hasValidMeals = false;
        hot_spots.forEach((hotspot) => {
          const { meal_instance_id } = hotspot.dish_config;
          if (!meal_instance_id) return;

          const meal = meals[meal_instance_id];
          if (!meal) return;

          hasValidMeals = true;
          const { category } = meal;
          if (!page.category_ids.includes(category.id)) {
            page.category_ids.push(category.id);
          }

          if (!existedCategoryIds.includes(category.id)) {
            existedCategoryIds.push(category.id);
          }
        });
        if (!hasValidMeals) unCategoriedPages.push(page);
      }

      if (!unCategoriedPages.length) continue;

      let categoryId;
      const menuForMeals = menusForMeals.find((_) => String(_.id) === menu_id);
      const { categories } = menuForMeals;

      if (!existedCategoryIds.length) {
        if (categories && categories.length) categoryId = String(categories[0].id);
      } else {
        existedCategoryIds.sort((a, b) => {
          const seq1 = categories.findIndex((_) => String(_.id) === a);
          const seq2 = categories.findIndex((_) => String(_.id) === b);
          return seq1 - seq2;
        });
        categoryId = existedCategoryIds[0];
      }
      unCategoriedPages.forEach((page) => {
        page.category_ids = categoryId ? [categoryId] : [];
      });
    }

    let response;

    try {
      response = await createOrUploadMenuDecorations([clonedMenuDecoration]);
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) {
      callback();
      return;
    }

    const newMenuDecorationMap = { ...menuDecorationState.menuDecorationMap };
    newMenuDecorationMap[layout] = clonedMenuDecoration;
    dispatch({
      type: MENU_DECORATE_ACTIONS.UPDATE_MENU_DECOARATION_SUCCESS,
      payload: { menuDecorationMap: newMenuDecorationMap },
    });
    callback();
  };
